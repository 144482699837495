import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'chart.js/auto';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { MapContainer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Row, Table } from 'react-bootstrap';
import { FaSpinner } from "react-icons/fa";

const Geomap = () => {
  const [districtData, setDistrictData] = useState([]);
  const [district, setDistrict] = useState('');
  const [village, setVillage] = useState('');
  const [districtVoters, setDistrictVoters] = useState([])
  const [villageVoters, setVillageVoters] = useState([])
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expired, setExpired] = useState('');
  const navigate = useNavigate();
  const [districtGeojson, setDistrictGeojson] = useState(null);
  const [loadingDistrict, setLoadingDistrict] = useState(false);
  const [loadingVillage, setLoadingVillage] = useState(false);

  useEffect(() => {
      refreshToken();
      getVoteByKecamatan();
      fetchDistrictGeoJSON(); //kecamatan
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get('https://api.masboy.id/token');
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpired(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expired * 1000 < currentDate.getTime()) {
        const response = await axios.get('https://api.masboy.id/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpired(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  const fetchDistrictGeoJSON = async () => {
    try {
      const response = await fetch('/assets/geojsonData/kecamatan.geojson');  // Path di folder public
      const data = await response.json();
      setDistrictGeojson(data);
    } catch (error) {
      console.error("Error loading GeoJSON:", error);
    }
  };
  
  const fetchDistrictVoters = async (district) => {
    try {
      const response = await axiosJWT.post(
        'https://api.masboy.id/total-voters-by-desa',
        {
          kecamatan: district
        }, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setDistrictVoters(response.data);
      setLoadingDistrict(false);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  
  const fetchVillageVoters = async (district, village) => {
    try {
      const response = await axiosJWT.post(
        'https://api.masboy.id/total-voters-by-tps',
        {
          kecamatan: district,
          desa: village
        }, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setVillageVoters(response.data);
      setLoadingVillage(false);
    } catch (error) {
      console.log(error);
    }
  };
  
  const getVoteByKecamatan = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/total-voters-by-kecamatan', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDistrictData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  
  const getColor = (persentase) => {
    if (persentase > 70) return '#20ff1f';
    if (persentase > 50) return '#ffda1f';
    return '#ff461f';
  };
  
  const getDistrictPercentage = (district) => {
    const kecamatan = districtData.find(kec => kec.kecamatan.toLowerCase() === district.toLowerCase());
    return kecamatan ? kecamatan.persentase : 0;
  };

  const geojsonStyle = (feature) => {
    const district = feature.properties.district;  // 'district' sesuai dengan properti di geoJSON
    const persentase = getDistrictPercentage(district);  // Dapatkan persentase dari data kecamatan
    const color = getColor(persentase);  // Tentukan warna berdasarkan persentase
    return {
      color: 'black',
      fillColor: color,
      weight: 0.6,
      fillOpacity: 0.7
    };
  };

  const onEachFeature = (feature, layer) => {
    const district = feature.properties.district;
    const persentase = getDistrictPercentage(district); // Mengubah persentase menjadi format % (0.4 -> 40%)
    
    const tooltipContent = `
      <strong>${district}</strong><br />
      Persentase: ${persentase.toFixed(2)}%
    `;

    layer.on({
      mouseover: (e) => {
        const layer = e.target;
        layer.bindTooltip(tooltipContent, {
          permanent: false,
          direction: 'center',
          className: 'district-tooltip'
        }).openTooltip();
        layer.setStyle({
          weight: 0.6,
          color: 'black',
          fillOpacity: 1
        });
      },
      mouseout: (e) => {
        const layer = e.target;
        layer.closeTooltip();
        layer.setStyle({
          weight: 0.6,
          color: 'black',
          fillOpacity: 0.7
        });
      },
      click: () => {
        // Set state untuk district
        setDistrict(feature.properties.district.toUpperCase());
        setVillageVoters([]);
        setDistrictVoters([]);
        setLoadingDistrict(true)
  
        // Panggil fungsi fetch data setelah setState
        fetchDistrictVoters(feature.properties.district.toUpperCase());
      }
    });
  };

  const position = [-7.0303889,109.3780111]
  return (
    <div className='d-flex'>
      <div className='pt-5'>
        <h4 className="text-center text-uppercase" style={{  fontWeight: 'bolder', color: '#008000' }}>
          Kabupaten Pemalang
        </h4>
        <Row style={{ height: '500px', width: '450px', marginLeft: '5px'}} >
          <MapContainer center={position} zoom={10} zoomControl={false} zoomSnap={false} dragging={false}>
            {districtGeojson && <GeoJSON data={districtGeojson} style={geojsonStyle} onEachFeature={onEachFeature} />}
          </MapContainer>
        </Row>
      </div>
      <div className='d-flex flex-column align-items-center just-content-start pt-5 w-100'>
        <h4 className="text-center text-uppercase mb-5" style={{  fontWeight: 'bolder', color: '#008000' }}>
          Progres Capaian Suara
        </h4>
        {/* tabel kelurahan */}
        <div className='d-flex gap-2 w-100'>
          <div className='d-flex flex-column w-100'>
            <h6 className="text-center text-uppercase" style={{  fontWeight: 'bolder', color: '#008000' }}>
              Kecamatan {district}  
            </h6>
            <Table striped bordered hover variant="light">
              <thead className='text-center'>
                <tr>
                  <th>Kelurahan/Desa</th>
                  <th>DPT</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {loadingDistrict && (
                  <div className='d-flex justify-content-center'>
                    <FaSpinner className="loading-spinner mt-2 mx-auto" style={{ fontSize: '3rem', fill: '#008000', background: 'none' }} />
                  </div>
                )}
                {districtVoters.length > 0 && 
                  districtVoters.map((voter, index) => (
                    <tr key={index} onClick={() => {
                      setVillage(voter.desa);
                      setVillageVoters([]);
                      setLoadingVillage(true)
                      fetchVillageVoters(district, village);
                      }}
                    >
                      <td style={{ fontWeight: 'bold' }}>{voter.desa}</td>
                      <td>{voter.dpt}</td>
                      <td className={`
                        ${voter.persentase > 70 ? 'text-success' :
                          voter.persentase > 50 ? 'text-warning' :
                          'text-danger'
                      }`}
                      style={{ fontWeight: 'bold' }}
                      >{voter.persentase}</td>
                    </tr>
                  )) 
                }
              </tbody>
            </Table>
          </div>
          {/* tabel TPS */}
          <div className='d-flex flex-column w-100'>
            <h6 className="text-center text-uppercase" style={{  fontWeight: 'bolder', color: '#008000' }}>
              Kelurahan/Desa {village} 
            </h6>
            <Table striped bordered hover variant="light">
              <thead className='text-center'>
                <tr>
                  <th>TPS</th>
                  <th>DPT</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {loadingVillage && (
                    <div className='d-flex justify-content-center'>
                    <FaSpinner className="loading-spinner mt-2 mx-auto" style={{ fontSize: '3rem', fill: '#008000', background: 'none' }} />
                  </div>
                )}
                {villageVoters.length > 0 && 
                  villageVoters.map((voter, index) => (
                    <tr key={index}>
                      <td style={{ fontWeight: 'bold' }}>
                        {voter.tps}
                      </td>
                      <td>{voter.dpt}</td>
                      <td className={` 
                        ${voter.persentase > 70 ? 'text-success' :
                          voter.persentase > 50 ? 'text-warning' :
                          'text-danger'
                      }`}
                      style={{ fontWeight: 'bold' }}
                      >{voter.persentase}</td>
                    </tr>
                  )) 
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Geomap;