import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';
import 'chart.js/auto';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import FotoPaslon from "./assets/login-2.png";

  
const DashboardFirst = () => {
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expired, setExpired] = useState('');
  const [id, setId] = useState(0);
  const [jabatan, setJabatan] = useState('');
  const [target, setTarget] = useState('');
  const [totalVoter, setTotalVoter] = useState('');
  const [newTarget, setNewTarget] = useState(''); // State untuk menyimpan target baru
  const navigate = useNavigate();
  const voterPercentage = ((totalVoter / target) * 100).toFixed(2);

  const paslon = {
    name: 'Mansur Hidayat - Bobby Dewantara',
    description: 'Calon Bupati dan Wakil Bupati Pemalang 2024'
  };

  useEffect(() => {
    refreshToken();
    getUser();
    getTarget();
    getTotalVoters();
  }, []);
  
  const refreshToken = async () => {
    try {
      const response = await axios.get('https://api.masboy.id/token');
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpired(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expired * 1000 < currentDate.getTime()) {
        const response = await axios.get('https://api.masboy.id/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpired(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getUser = async () => {
    try {
      const response = await axios.get('https://api.masboy.id/token');
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setId(decoded.userId);
      try {
        const response = await axiosJWT.get(`https://api.masboy.id/users/${decoded.userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setJabatan(response.data[0].role);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTarget = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/target', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTarget(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalVoters = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/total-voters', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTotalVoter(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateTarget = async () => {
    try {
      await axiosJWT.post(
        'https://api.masboy.id/update-target', 
        { target: newTarget }, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTarget(newTarget); // Update state target dengan nilai baru
      setNewTarget(''); // Reset input setelah submit
    } catch (error) {
      console.log(error);
    }
  };

  const pieData = {
    labels: ['Pemilih Terdata', 'Sisa Target'],
    datasets: [
      {
        backgroundColor: ['#fbb31a', '#008000'],
        data: [totalVoter, target - totalVoter],
      },
    ],
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-start align-items-start">
        <Col md={6}>
          <div className="text-center ms-5">
            <Card.Img variant="top" src={FotoPaslon} alt="Foto Paslon" style={{ height: '400px', objectFit: 'contain' }}  />
            {/* <Card.Body>
              <Card.Title className='text-uppercase'><span style={{ fontWeight: 'bold', color: '#008000' }}>{paslon.name}</span></Card.Title>
              <Card.Text>{paslon.description}</Card.Text>
            </Card.Body> */}
          {jabatan === 'Admin' && (
            <Form className='mt-5'>
              <Form.Group controlId="formTarget">
                <Form.Label style={{ fontWeight: 'bolder', color: '#008000' }}>Set Target Baru</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Masukkan target baru"
                  value={newTarget}
                  onChange={(e) => setNewTarget(e.target.value)}
                />
                <Button className="mt-3" onClick={updateTarget} style={{ backgroundColor: '#008000', border: 'none', fontWeight: 'bold' }}>
                  Perbarui Target
                </Button>
              </Form.Group>
            </Form>
          )}
          </div>
        </Col>
        <Col md={6} className="d-flex align-items-center justify-content-center">
          <div style={{ width: '100%', maxWidth: '400px' }}>
            <h4 className="text-center text-uppercase" style={{  fontWeight: 'bolder', color: '#008000' }}>Persentase Pemilih Terdata</h4>
            <div className='mx-auto' style={{ maxWidth: '320px' }}>
                <Pie data={pieData} color=''/>
            </div>
            <div className='d-flex flex-column mt-3'>
              <p className="text-left my-0"><span style={{ fontWeight: 'bold', color: '#008000' }}>Total Pemilih:</span> {totalVoter}</p>
              <p className="text-left my-0"><span style={{ fontWeight: 'bold', color: '#008000' }}>Target: </span> {target} ({voterPercentage}%)</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardFirst;
