import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Alert, Image } from 'react-bootstrap';
import garuda from './assets/koalisi/garuda.png';
import demokrat from './assets/koalisi/demokrat.png';
import gerindra from './assets/koalisi/gerindra.png';
import nasdem from './assets/koalisi/nasdem.png';
import pan from './assets/koalisi/pan.png';
import pdip from './assets/koalisi/pdip.png';
import pkn from './assets/koalisi/pkn.png';
import pks from './assets/koalisi/pks.png';
import ppp from './assets/koalisi/ppp.png';
// import photo from './assets/login.png'
import photo from './assets/login-2.png'

const Login = () => {
  const [nik, setNik] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  const Auth = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://api.masboy.id/login', {
        nik: nik,
        password: password,
      });
      navigate('/daftar-dpt');
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <Container fluid className="d-flex flex-column align-items-center justify-content-center min-vh-100" style={{ backgroundImage: "linear-gradient(to right bottom, #008000, #222222)" }}>
      <div className="bg-white d-flex align-items-end justify-content-center rounded">
        <div className='d-flex align-items-end justify-content-end'>
          <Image src={photo} style={{ width: 'auto', height: '400px' }}/>
        </div>
        <div>
          <Form onSubmit={Auth} className="p-4 bg-white rounded position-relative">
            <Row className='mx-auto gap-3 d-flex justify-content-center'>
                <Image src={ppp} className='px-0' style={{ width: 'auto', height: '30px'}} />
                <Image src={gerindra} className='px-0' style={{ width: 'auto', height: '30px'}} />
                <Image src={pan} className='px-0' style={{ width: 'auto', height: '30px'}} />
                <Image src={pks} className='px-0' style={{ width: 'auto', height: '30px'}} />
                <Image src={pdip} className='px-0' style={{ width: 'auto', height: '30px'}} />
                <Image src={demokrat} className='px-0' style={{ width: 'auto', height: '30px'}} />
                <Image src={nasdem} className='px-0' style={{ width: 'auto', height: '30px'}} />
                <Image src={garuda} className='px-0' style={{ width: 'auto', height: '30px'}} />
                <Image src={pkn} className='px-0' style={{ width: 'auto', height: '30px'}} />
            </Row>
            <h2 className="text-center my-4 text-uppercase" style={{ fontWeight: 'bolder' }}><span className="text-decoration-none" style={{ color: '#008000' }}>Login</span></h2>
            {msg && <Alert variant="danger" className="text-center">{msg}</Alert>}
            <Form.Group controlId="email" className="mb-3">
              <Form.Label>NIK</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Masukkan NIK" 
                value={nik} 
                onChange={(e) => setNik(e.target.value)} 
              />
            </Form.Group>
            <Form.Group controlId="password" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="*******" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
              />
            </Form.Group>
            <Button style={{ backgroundColor: '#008000', border: 'none', fontWeight: 'bold' }} type="submit" className="mb-3 py-2 px-4 mx-auto">
              Login
            </Button>
            <div className='w-100 position-absolute bottom-0 start-0' style={{ height: '15px', backgroundColor: '#008000' }}></div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default Login;
