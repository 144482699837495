import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Alert, Row, Col } from 'react-bootstrap';

const CreateUserPage = () => {
  const [nik, setNik] = useState('');
  const [nama, setNama] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [noHp, setNoHp] = useState('');
  const [jabatan, setJabatan] = useState('');
  const [kecamatan, setKecamatan] = useState('');
  const [desa, setDesa] = useState('');
  const [tps, setTps] = useState('');
  const [role, setRole] = useState('Rekruter');
  const [msg, setMsg] = useState('');
  const [alertMode, setAlertMode] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.masboy.id/users', {
        nik: nik,
        nama: nama,
        password: password,
        confPassword: confPassword,
        no_hp: noHp,
        jabatan: jabatan,
        kecamatan: kecamatan,
        desa: desa,
        tps: tps,
        role: role,
      });
      setMsg(response.data.msg);
      setAlertMode('success');
      // Redirect ke halaman lain setelah berhasil, jika perlu
      navigate('/rekruters');
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
        setAlertMode('danger');
      }
    }
  };

  return (
    <Container className="mt-5 p-5">
      <h2 className="mb-5 text-center text-uppercase h1"
        style={{ 
          color: '#008000',
          fontWeight: 'bolder'
         }}>Buat User Baru</h2>
      <hr />
      {msg && <Alert variant={alertMode} className="text-center">{msg}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="nik">
              <Form.Label>NIK</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkan NIK"
                value={nik}
                onChange={(e) => setNik(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="nama">
              <Form.Label>Nama</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkan Nama"
                value={nama}
                onChange={(e) => setNama(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Masukkan Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="confPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={confPassword}
                onChange={(e) => setConfPassword(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="noHp">
              <Form.Label>No HP</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkan No HP"
                value={noHp}
                onChange={(e) => setNoHp(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="jabatan">
              <Form.Label>Jabatan</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkan Jabatan"
                value={jabatan}
                onChange={(e) => setJabatan(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="kecamatan">
              <Form.Label>Kecamatan</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkan Kecamatan"
                value={kecamatan}
                onChange={(e) => setKecamatan(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="desa">
              <Form.Label>Desa</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkan Desa"
                value={desa}
                onChange={(e) => setDesa(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="tps">
              <Form.Label>TPS</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkan TPS"
                value={tps}
                onChange={(e) => setTps(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="role">
              <Form.Label>Role</Form.Label>
              <Form.Control as="select" value={role} onChange={(e) => setRole(e.target.value)} required>
                <option value="Rekruter">Rekruter</option>
                <option value="Admin">Admin</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Button type="submit" style={{ 
                      backgroundColor: '#008000',
                      border: 'none'
                     }}>
          Buat User
        </Button>
      </Form>
    </Container>
  );
};

export default CreateUserPage;
