import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Container, Row, Col, Alert, Form, Pagination } from 'react-bootstrap';

const DaftarDPT = () => {
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expired, setExpired] = useState('');
  const [voters, setVoters] = useState({});
  const [kecamatanOptions, setKecamatanOptions] = useState([]);
  const [desaOptions, setDesaOptions] = useState([]);
  const [selectedKecamatan, setSelectedKecamatan] = useState('');
  const [selectedDesa, setSelectedDesa] = useState('');
  const [selectedTps, setSelectedTps] = useState('');
  const [tpsOptions, setTpsOptions] = useState([]);
  const [selectedVoterIds, setSelectedVoterIds] = useState([]);
  const [msg, setMsg] = useState('');
  const [alertMode, setAlertMode] = useState('');
  
  // State for search
  const [searchName, setSearchName] = useState('');

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
    fetchKecamatanOptions();
    getVoters();
  }, []);

  useEffect(() => {
    if (selectedDesa) {
      fetchTpsOptions(selectedDesa);
    } else {
      setTpsOptions([]);
      setSelectedTps('');
    }
  }, [selectedDesa]);

  useEffect(() => {
    if (selectedKecamatan) {
      fetchDesaOptions(selectedKecamatan);
    } else {
      setDesaOptions([]);
      setSelectedDesa('');
    }
    getVoters();
  }, [selectedKecamatan, selectedDesa, selectedTps]);

  useEffect(() => {
    getVoters();
  }, [rowsPerPage, currentPage]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`https://api.masboy.id/token`);
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpired(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expired * 1000 < currentDate.getTime()) {
        const response = await axios.get('https://api.masboy.id/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpired(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getVoters = async () => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/voters`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: selectedKecamatan,
          desa: selectedDesa,
          tps: selectedTps,
          page: currentPage, // Menyertakan parameter untuk halaman saat meminta data
          limit: rowsPerPage, // Menyertakan parameter untuk batasan data saat meminta data
        },
      });
      setVoters(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchKecamatanOptions = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/kecamatan', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setKecamatanOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDesaOptions = async (kecamatan) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/desa`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: kecamatan,
        },
      });
      setDesaOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTpsOptions = async (desa) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/tps`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          desa: desa,
        },
      });
      setTpsOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKecamatanChange = (e) => {
    setSelectedKecamatan(e.target.value);
    setSelectedDesa(''); // Reset desa when kecamatan changes
    setCurrentPage(1); // Reset to the first page when kecamatan changes
  };

  const handleDesaChange = (e) => {
    setSelectedDesa(e.target.value);
    setCurrentPage(1); // Reset to the first page when kecamatan changes
  };

  const handleTpsChange = (e) => {
    setSelectedTps(e.target.value);
    setCurrentPage(1); // Reset to the first page when kecamatan changes
  };

  const handleVoterSelect = (id) => {
    setSelectedVoterIds((prev) =>
      prev.includes(id) ? prev.filter((voterId) => voterId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    if (selectedVoterIds.length === currentVoters.length) {
      setSelectedVoterIds([]); // Deselect all if all are selected
    } else {
      const allVoterIds = currentVoters.map(voter => voter.id);
      setSelectedVoterIds(allVoterIds); // Select all
    }
  };

  const handleSubmit = async () => {
    if (selectedVoterIds.length === 0) {
      setMsg('Silakan pilih pemilih terlebih dahulu!');
      setAlertMode('danger');
      return;
    }

    try {
      const response = await axiosJWT.post('https://api.masboy.id/assign-voter', {
        voterIds: selectedVoterIds,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(response.data.msg);
      setAlertMode('success');
      setSelectedVoterIds([]); // Reset selected voter IDs after submission
      getVoters(); // Refresh the voter list
    } catch (error) {
      console.log(error);
      setMsg('Terjadi kesalahan saat menambahkan pemilih!');
      setAlertMode('danger');
    }
  };

  const currentVoters = voters.data?.filter(voter => voter.nama.toLowerCase().includes(searchName.toLowerCase()));

  // Calculate total pages
  const totalVoters = voters.totalVoters;
  const totalPages = Math.ceil(totalVoters / rowsPerPage);
  const maxPageNumbersToShow = 5;

  // Calculate the range of page numbers to show
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing rows per page
  };

  return (
    <Container className="mt-5 p-5">
      <h2 className="mb-5 text-center text-uppercase h1"
        style={{ 
          color: '#008000',
          fontWeight: 'bolder'
         }}
      >Daftar DPT Kabupaten Pemalang</h2>
      <hr />
      {msg && <Alert variant={alertMode} className="text-center" role='button' onClick={() => setMsg('')}>{msg}</Alert>}
      <Row className="mb-4">
        <Col>
          <Form.Select value={selectedKecamatan} onChange={handleKecamatanChange}>
            <option value="">Pilih Kecamatan</option>
            {kecamatanOptions.map((kec) => (
              <option key={kec.id} value={kec.kecamatan}>{kec.kecamatan}</option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Select value={selectedDesa} onChange={handleDesaChange} disabled={!selectedKecamatan}>
            <option value="">Pilih Desa</option>
            {desaOptions.map((desa) => (
              <option key={desa.id} value={desa.desa}>{desa.desa}</option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Select value={selectedTps} onChange={handleTpsChange} disabled={!selectedDesa}>
            <option value="">Pilih TPS</option>
            {tpsOptions.map((tps) => (
              <option key={tps.id} value={tps.tps}>{tps.tps}</option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <div className="sticky-top bg-light border p-3">
        <Row className="align-items-center">
          <Col md={6}>
            <Form.Control
              type="text"
              placeholder="Cari berdasarkan nama..."
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
          </Col>
          <Col md={2}>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange}>
            <option value={100}>100</option>
            <option value={250}>250</option>
            <option value={500}>500</option>
            <option value={totalVoters}>Semua</option>
          </Form.Select>
          </Col>
          <Col md={4} className="text-end">
            <Button onClick={handleSubmit} style={{ backgroundColor: '#008000', border: 'none' }}>Tambahkan Pemilih Terpilih</Button>
            <span className="ms-3">{selectedVoterIds.length} pemilih terpilih</span>
          </Col>
        </Row>
      </div>
      {voters.data?.length > 0 ? (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>No</th>
                <th>Nama</th>
                <th>Umur</th>
                <th>Kelamin</th>
                <th>Kecamatan</th>
                <th>Desa</th>
                <th>TPS</th>
                <th>
                <Form.Check
                  type="checkbox"
                  onChange={handleSelectAll}
                />
                </th>
              </tr>
            </thead>
            <tbody>
              {currentVoters.map((voter, index) => (
                <tr key={voter.id}>
                  <td>{index+1+(currentPage*rowsPerPage-rowsPerPage)}</td>
                  <td>{voter.nama}</td>
                  <td>{voter.umur}</td>
                  <td>{voter.kelamin}</td>
                  <td>{voter.kecamatan}</td>
                  <td>{voter.desa}</td>
                  <td>{voter.tps}</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={selectedVoterIds.includes(voter.id)}
                      onChange={() => handleVoterSelect(voter.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination className="justify-content-center">
            {startPage > 1 && (
              <Pagination.First onClick={() => paginate(1)} />
            )}
            {Array.from({ length: endPage - startPage + 1 }, (_, idx) => startPage + idx).map((number) => (
              <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => paginate(number)}
                className={number === currentPage ? 'pagination-item-active' : ''}
              >
                {number}
              </Pagination.Item>
            ))}
            {endPage < totalPages && (
              <Pagination.Last onClick={() => paginate(totalPages)} />
            )}
          </Pagination>
        </>
      ) : (
        <h3 className="mb-2 text-danger text-center text-uppercase h6 mt-3"
        style={{ 
          color: '#008000'
        }}
        >Belum ada DPT!</h3>
      )}
    </Container>
  );
};

export default DaftarDPT;
