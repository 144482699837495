import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, ProgressBar, Form } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { FaMale, FaFemale } from "react-icons/fa";

const MonitoringSection = () => {
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expired, setExpired] = useState('');
  const [voteByKecamatan, setVoteByKecamatan] = useState([]);
  const [dpt, setDpt] = useState(0);
  const [kecamatanCount, setKecamatanCount] = useState(0);
  const [desaCount, setDesaCount] = useState(0);
  const [tpsCount, setTpsCount] = useState(0);
  const [totalVoter, setTotalVoter] = useState(0);
  const [kecamatanOptions, setKecamatanOptions] = useState([]);
  const [desaOptions, setDesaOptions] = useState([]);
  const [selectedKecamatan, setSelectedKecamatan] = useState('');
  const [selectedDesa, setSelectedDesa] = useState('');
  const [selectedTps, setSelectedTps] = useState('');
  const [tpsOptions, setTpsOptions] = useState([]);
  const [votersDemographic, setVotersDemographic] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
    getVoteByKecamatan();
    getTotalDpt();
    getTotalVoters();
    getKecamatanCount();
    getVotersDemographic();
    getDesaCount();
    getTpsCount();
  }, []);

  useEffect(() => {
    refreshToken();
    fetchKecamatanOptions();
  }, []);

  useEffect(() => {
    if (selectedKecamatan) {
      fetchDesaOptions(selectedKecamatan);
    } else {
      setDesaOptions([]);
      setSelectedDesa('');
    }
  }, [selectedKecamatan, selectedDesa, selectedTps]);

  const refreshToken = async () => {
    try {
      const response = await axios.get('https://api.masboy.id/token');
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpired(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expired * 1000 < currentDate.getTime()) {
        const response = await axios.get('https://api.masboy.id/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwtDecode(response.data.accessToken);
        setName(decoded.name);
        setExpired(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  const getVoteByKecamatan = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/total-voters-by-kecamatan', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVoteByKecamatan(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalDpt = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/total-dpt', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDpt(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getVotersDemographic = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/voters-demographic', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVotersDemographic(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalVoters = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/total-voters', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTotalVoter(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchKecamatanOptions = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/kecamatan', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setKecamatanOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDesaOptions = async (kecamatan) => {
    try {
      const response = await axiosJWT.get(`https://api.masboy.id/desa`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          kecamatan: kecamatan,
        },
      });
      setDesaOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKecamatanChange = (e) => {
    setSelectedKecamatan(e.target.value);
    setSelectedDesa(''); // Reset desa when kecamatan changes
  };

  const handleDesaChange = (e) => {
    setSelectedDesa(e.target.value);
  };

  const getKecamatanCount = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/kecamatan-count', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setKecamatanCount(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDesaCount = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/desa-count', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDesaCount(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTpsCount = async () => {
    try {
      const response = await axiosJWT.get('https://api.masboy.id/tps-count', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTpsCount(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Statistik keseluruhan
  const statistics = {
    totalDPT: dpt,
    jumlahPemilih: totalVoter, // Contoh jumlah pemilih
    totalKecamatan: kecamatanCount,
    totalDesa: desaCount,
    jumlahTPS: tpsCount,
  };

  // Data untuk bar chart
  const barData = {
    labels: voteByKecamatan.map(item => item.kecamatan),
    datasets: [
      {
        label: 'Jumlah Pemilih',
        backgroundColor: '#008000',
        data: voteByKecamatan.map(item => item.jumlah),
      },
    ],
  };

  // Opsi untuk bar chart
  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  // Komponen untuk menampilkan bar usia dan jenis kelamin
  const AgeGenderBar = ({ title, value, color, kelamin }) => (
    <div className="d-flex align-items-center mb-2">
      <span style={{ width: '70px' }}>{title}</span>
      <ProgressBar
        now={value}
        max={1}
        style={{ flexGrow: 1, margin: '0 10px' }}
        variant={color}
      />
      <span>{(value*100).toFixed(2)} %</span>
    </div>
  );

  return (
  <>
  <Container className="my-5">
      <Row className='px-5'>
        {/* Chart Section */}
        <h4 className="text-center h1 mb-5 text-uppercase" style={{ color: '#008000', fontWeight: 'bolder' }}>Monitoring Wilayah</h4>
        {/* <Row className="mb-4">
        <Col>
          <Form.Select value={selectedKecamatan} onChange={handleKecamatanChange}>
            <option value="">Pilih Kecamatan</option>
            {kecamatanOptions.map((kec) => (
              <option key={kec.id} value={kec.kecamatan}>{kec.kecamatan}</option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Select value={selectedDesa} onChange={handleDesaChange} disabled={!selectedKecamatan}>
            <option value="">Pilih Desa</option>
            {desaOptions.map((desa) => (
              <option key={desa.id} value={desa.desa}>{desa.desa}</option>
            ))}
          </Form.Select>
        </Col>
      </Row> */}
        <Col md={8}>
          <div style={{ height: '320px', width: '560px', overflowX: 'auto' }}>
            <Bar data={barData} options={barOptions} />
          </div>
        </Col>

        {/* Statistik Section */}
        <Col md={4} className='my-auto p-0'>
          <div className="container ">
            <div className='row gap-3 mb-3'>
              <Card border='success' text="white" style={{ backgroundColor: '#008000' }} className="col text-center">
                <Card.Body>
                  <Card.Title className='h6'>Jumlah Pemilih</Card.Title>
                  <Card.Text className='h3'>{statistics.jumlahPemilih.toLocaleString()}</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='row gap-3 mb-3'>
              <Card bg="white" border='success' text="success" className="col text-center p-1">
                <Card.Body>
                  <Card.Title className='h6'>Total DPT</Card.Title>
                  <Card.Text className='h4'>{statistics.totalDPT.toLocaleString()}</Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='row gap-3 mb-3'>
              <Card bg="white" border='success' text="success" className="col text-center">
                <Card.Body>
                  <Card.Title style={{ fontSize: '0.7rem' }}>Kecamatan</Card.Title>
                  <Card.Text className='h4'>{statistics.totalKecamatan}</Card.Text>
                </Card.Body>
              </Card>
              <Card bg="white" border='success' text="success" className="col text-center">
                <Card.Body>
                  <Card.Title style={{ fontSize: '0.7rem' }}>Kel./Desa</Card.Title>
                  <Card.Text className='h4'>{statistics.totalDesa}</Card.Text>
                </Card.Body>
              </Card>
              <Card bg="white" border='success' text="success" className="col text-center p-1">
                <Card.Body>
                  <Card.Title style={{ fontSize: '0.7rem' }}>TPS</Card.Title>
                  <Card.Text className='h4 font-weight-bold'>{statistics.jumlahTPS.toLocaleString()}</Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    <hr />
    <Container fluid className="my-4">
    <Row>
      <Col>
        <div className="p-3">
          <Card.Body>
            <h4 className="text-center h1 text-uppercase font-weight-bold mb-5" style={{ color: '#008000', fontWeight: 'bolder' }}>Statistik Pemilih</h4>
            <p className='text-center'
                style={{ 
                  fontWeight: 'bold'
                 }}
            >Total Pemilih</p>
            <h6 
                className="text-center h1 mb-3 py-2 w-25 mx-auto rounded" 
                style={{ 
                  border: '3px solid #008000',
                  color: '#008000',
                  fontWeight: 'bolder'
                }}>
              {statistics.jumlahPemilih}
            </h6>
            <Row className="text-center">
              <Col>
                <p className='h3' style={{ color: '#02ccFE' }}>
                  <FaMale /> {votersDemographic.totalLaki}
                </p>
              </Col>
              <Col>
                <p className='h3 text-danger'>
                  <FaFemale /> {votersDemographic.totalPerempuan}
                </p>
              </Col>
            </Row>
            <h6 className="mt-4">Usia Laki - Laki</h6>
            {votersDemographic.laki?.map((item, index) => (
              <AgeGenderBar kelamin={'L'} key={index} title={item.generasi} value={parseInt(item.total)/votersDemographic.totalLaki} color="info" />
            ))}
            <h6 className="mt-4">Usia Perempuan</h6>
            {votersDemographic.perempuan?.map((item, index) => (
              <AgeGenderBar kelamin={'P'} key={index} title={item.generasi} value={parseInt(item.total)/votersDemographic.totalPerempuan} color="danger" />
            ))}
          </Card.Body>
        </div>
      </Col>
    </Row>
  </Container>
  </>
  );
};

export default MonitoringSection;
